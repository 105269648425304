import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import api from '@/api/api-config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },{
    path: '/join-us',
    name: 'join-us',
    component: () => import('../views/JoinUsView.vue')
  },{
    path: '/bd',
    name: 'business- development',
    component: () => import('../views/BdView.vue')
  },{
    path: '/lect-video',
    name: 'lect-video',
    component: () => import('../views/LectVideoView.vue')
  },{
    path: '/search',
    name: 'search',
    component: () => import('../views/search/SearchView.vue')
  },{
    path: '/check-sol',
    name: 'check-sol',
    component: () => import('../views/search/CheckSolView.vue')
  },{
    path: '/paper-bank/:examBoard',
    name: 'paper-bank-examBoard',
    component: () => import('../views/paper-bank/PaperBankView.vue')
  },{
    path: '/paper-bank/:examBoard/:subject',
    name: 'paper-bank-examBoard-subject',
    component: () => import('../views/paper-bank/PaperBankSubjectView.vue'),
  },{
    path: '/paper-pdf-viewer',
    name: 'paper-pdf-viewer',
    component: () => import('../views/paper-bank/PaperBankPdfView.vue'),
  },{
    path: '/topic-bank',
    name: 'topic-bank',
    component: () => import('../views/topic-bank/TopicBankView.vue')
  },{
    path: '/topic-bank/:examBoard/:subject',
    name: 'topic-bank-examBoard-subject',
    component: () => import('../views/topic-bank/TopicBankSubjectView.vue'),
    meta: { requiresAuth: true }
  },{
    path: '/topic-pdf-viewer',
    name: 'topic-bank-pdf-viewer',
    component: () => import('../views/topic-bank/TopicBankPdfView.vue'),
    meta: { requiresAuth: true }
  },{
    path: '/internal-bank/:volId',
    name: 'internal-volume-by-volId',
    component: () => import('../views/lect-bank/InternalVolumeView.vue')
  },{
    path: '/internal-pdf-viewer',
    name: 'internal-pdf-viewer',
    component: () => import('../views/lect-bank/InternalPdfView.vue')
  },{
    path: '/lect-bank/:examBoard',
    name: 'lect-bank-examBoard',
    component: () => import('../views/lect-bank/LectBankSubjectView.vue')
  },{
    path: '/lect-pdf-viewer',
    name: 'lect-bank-pdf-viewer',
    component: () => import('../views/lect-bank/LectBankPdfView.vue')
  },{
    path: '/topic-fork',
    name: 'topic-fork',
    component: () => import('../views/topic-fork/TopicForkView.vue')
  },{
    path: '/topic-fork/paper-topic/:examBoard/:subject',
    name: 'paper-fork-examBoard-subject',
    component: () => import('../views/topic-fork/PaperForkSubjectView.vue'),
  },{
    path: '/topic-fork/topic-topic/:examBoard/:subject',
    name: 'topic-fork-examBoard-subject',
    component: () => import('../views/topic-fork/TopicForkSubjectView.vue')
  },{
    path: '/paper-topic-pdf-viewer/:examBoard/:subject',
    name: 'paper-topic-pdf-viewer',
    component: () => import('../views/topic-fork/PaperForkPdfView.vue'),
  },{
    path: '/topic-topic-pdf-viewer/:examBoard/:subject',
    name: 'topic-topic-pdf-viewer',
    component: () => import('../views/topic-fork/TopicForkPdfView.vue')
  },{
    path: '/topic-topic-pdf-dl-viewer/:examBoard/:subject',
    name: 'topic-topic-pdf-viewer',
    component: () => import('../views/topic-fork/TopicForkPdfDownloadView.vue')
  },{
    path: '/tch-tutor/:id',
    name: 'tch-tutor',
    component: () => import('../views/TutorView.vue'),
  },{
    path: '*',
    name: 'error',
    component: HomeView,
  }
  // {
  //   path: '/editor-tutors',
  //   name: 'editor-tutors',
  //   component: () => import('../views/EditorTutorView.vue'),
  // },
  // {
  //   path: '/editor-students',
  //   name: 'editor-students',
  //   component: () => import('../views/EditorStudentView.vue'),
  // },
  // {
  //   path: '/more-info/:category/:contest',
  //   name: 'more-info',
  //   component: () => import('../views/del/MoreInfoView.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savedPosition){
    if (savedPosition){
      // 按下 后退/前进 按钮时，类似浏览器的原生表现
      return savedPosition
    }else {
      // 让页面滚动到顶部
      return {
        x: 0,
        y: 0
      } 
    }  
  }
})

router.beforeEach((to, from, next)=>{
  if(to.meta.requiresAuth){
    const token = localStorage.getItem('token')
    
    if(token){
      api({
        method: 'post',
        url: '/verifyToken',
        headers: {
          authorization: token
        }
      }).then(res => {
        if(res.data == null){
          localStorage.clear()
          next('/topic-bank')
        } 
       })
      // eslint-disable-next-line
      .catch(err => {
        next('/topic-bank') 
      })
    } else {
      next('/topic-bank')
    }
  } 

  next()
})

export default router
